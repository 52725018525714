
ul:where([role="list"]) {
  list-style: none;
  margin: 0;
  padding: 0;
}

.section {
  padding-block: clamp(2rem, 5vw, 5rem);
}

.our_team {
  width: min(100% - 2rem, 65rem);
  margin-inline: auto;
}

.grids {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 
    repeat(auto-fit, minmax(min(35ch, 100%), 1fr));
}

.section-title {
  font-size: clamp(2rem, 5vw, 3rem);
  text-align: center;
  font-weight: bold;
  color: #fff;
  margin-bottom: 0.5em;
}

.flex-group {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: center;
}

.flow-content {
  display: grid;
  align-content: start;
  gap: 1rem;
}

[data-spacing="sm"] {
  gap: 0.25rem;
}

.mx-auto {
  width: fit-content;
  margin-inline: auto;
}

.cards {
  display: grid;
  gap: 0.5rem;
  
  position: relative;
  overflow: hidden;
  
  width: min(100% - 2rem, 35ch);
  margin-inline: auto;
  border-radius: 0.5rem;
  
  background-color: #14273e;
  text-align: center;
}

.card__name {
  font-weight: 700;
  color: #fff;
}

.card__position {
  font-style: italic;
  color: #fff;
}

.card__img {
  width: 5rem;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #ccc;
}

.card__front {
  padding: 2em 2em 2.5em 2em;
  transition: 
    opacity 250ms ease,
    transform 450ms ease;
}

.card[data-visible="false"] .card__front {
  opacity: 1;
  transform: translateY(0);
}

.card[data-visible="true"] .card__front {
  opacity: 0;
  transform: translateY(100%);
  transition: 
    opacity 150ms ease,
    transform 450ms ease 50ms;
}