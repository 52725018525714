.process_about{
  background: #ccc;
}

.know_about p{
  margin: 25px;
  font-size: 18px;
  text-align: justify;
  margin-left: 60px;
  margin-right: 60px;
}

.carousel-container {
  /* margin-top: 5rem; */
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
}

.carousel .slider-wrapper {
  display: flex;
  align-items: center;
}

.carousel .slider-wrapper.axis-horizontal .slider {
  flex-wrap: nowrap;
}

.carousel .slider {
  display: flex;
  align-items: center;
  width: 100%;
}

.carousel .slide {
  flex: 0 0 auto;
  width: 100%;
  margin: 0;
  padding: 0;
}

.carousel .slide .carousel-image-wrapper {
  position: relative;
  padding-top: 56.25%;
}

.carousel .slide .carousel-image-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}



/* Banner */

/* .main-image {
  position: relative;
  background-size: cover;
  height: 500px;
  overflow: hidden;
  background-color: #000;
  
}

.containerr {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  
}

.containerr h1 {
  margin: 0;
  font-size: 66px;
}

.containerr h1 span {
  border: 6px solid white;
  padding: 6px 14px;
  display: inline-block;
  color: #fff;
} */

.main-image {
  position: relative;
  background-size: cover;
  height: 500px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 1) !important; /* Add background color here */
}

.main-image {
  position: relative;
  background-size: contain; /* Change from 'cover' to 'contain' */
  background-position: center;
  height: 500px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 1) !important;
}

.containerr {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Adjust the positioning */
  text-align: center;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  padding: 20px; /* Add padding to create space */
}

.containerr h1 {
  margin: 0;
  font-size: 66px;
}

.containerr h1 span {
  border: 6px solid #ff4500;
  padding: 6px 14px;
  display: inline-block;
  color: #ff4500;
}

@media only screen and (max-width: 768px) {
  .main-image {
    height: 300px; /* Adjust the height for mobile layout */
  }

  .containerr h1 {
    font-size: 24px; /* Adjust the font size for mobile layout */
    margin-top: 40px;
  }
  .carousel-container{
    margin-top: 80px;
  }
}

/* 
.cen {
  margin: 20px;
  display: block;
  font-size: 22px;
} */
