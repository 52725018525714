  .cards__container {
    display: grid;
    grid-template-columns: 1fr;
    margin: 2.5rem auto;
    max-width: 53.125rem;
    width: 95%;
    gap: 3.75rem;
  }
  
  .card {
    display: grid;
    grid-template-columns: 1fr;
  }
  
  .card__thumb {
    overflow: hidden;
    background-color: #6a6a6a;
  }
  
  .card__thumb img {
    transition: all 0.3s ease;
    height: 30rem;

  }
  
  .card__thumb:hover img {
    transform: scale(1.1);
  }

  .card__timestamp{
    color: orangered;
  }
  
  .card__content {
    background-color: rgb(228, 222, 214);
    padding: 2.1875rem 1.5625rem 3.4375rem;
    position: relative;
    text-align: center;
  }
  
  .card__title {
    color: #171717;
    font-family: "Playfair Display", serif;
    font-size: 2.1875rem;
    font-weight: 400;
    margin-bottom: 1.25rem;
  }
  
  .card__text {
    line-height: 1.7;
    text-align: justify;
  }
  
  .card__btn {
    background: #444;
    border: none;
    bottom: -1.25rem;
    left: 50%;
    padding: 0.625rem;
    position: absolute;
    transform: translateX(-50%);
    transition: all 0.3s ease;
  }
  
  .card__btn:hover,
  .card__btn:focus {
    background-color: #6a6a6a;
  }
  
  .card__btn img {
    width: 1.25rem;
  }
  
  @media (min-width: 37.5rem) {
    .card {
      grid-template-columns: auto minmax(6.25rem, 25rem) auto;
      grid-template-rows: repeat(4, auto);
    }
  
    .card__thumb {
      width: 100%;
      max-width: 34.375rem;
      justify-self: center;
      grid-area: 1 / 1 / -2 / -1;
    }
  
    .card__content {
      grid-area: 3 / 2 / -1 / -2;
      padding: 2.8125rem 2.8125rem 3.4375rem;
    }
  }
  
  @media (min-width: 56.25rem) {
    .card {
      grid-template-columns: auto 4.375rem minmax(6.25rem, 25rem);
      grid-template-rows: repeat(3, auto);
    }
  
    .card:nth-child(even) {
      grid-template-columns: minmax(6.25rem, 25rem) 4.375rem auto;
    }
  
    .card__thumb {
      grid-area: 1 / 1 / -1 / 3;
    }
  
    .card:nth-child(even) .card__thumb {
      grid-area: 1 / 2 / -1 / -1;
    }
  
    .card__content {
      grid-area: 2 / 2 / -2 / -1;
      text-align: left;
    }
  
    .card:nth-child(even) .card__content {
      text-align: left;
      grid-area: 2 / 1 / -2 / 3;
    }
  }
  