.process {
    width: 100%;
    padding: 0 15px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    list-style: none;
  }
  
  .process__item {
    user-select: none;
    text-align: center;
    position: relative;
    padding: 15px 35px;
    transition: 0.4s ease-in-out;
  }
  
  .process__item:hover {
    background: #f5f5f5;
  }
  
  .process__item:hover .process__number {
    transform: translateY(5px);
    color: orangered;
  }
  
  .process__number {
    font-size: 90px;
    -webkit-text-stroke: 1px orangered;
    display: block;
    color: transparent;
    font-weight: 700;
    transition: 0.4s ease-in-out;
  }
  
  .process__title {
    display: block;
    font-weight: 700;
    letter-spacing: 1.5px;
    font-size: 1.2rem;
    color: #000;
    text-transform: uppercase;
    margin-top: 30px;
  }
  
  .process__subtitle {
    display: block;
    /* font-family: "Josefin Slab"; */
    font-size: 14px;
    color: #a6a6a6;
    margin-top: 30px;
    line-height: 1.5;
  }
  
  @media (min-width: 768px) {
    .process {
      list-style: none;
      display: inline-block;
    }
  
    .process__item {
      width: 49%;
      display: inline-block;
    }
  }
  
  @media (min-width: 1200px) {
    .process {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      list-style: none;
    }
  
    .process__item {
      width: 100%;
    }
  
    .process__item:not(:last-of-type)::after {
      content: "";
      width: 1px;
      height: 75%;
      background: #a6a6a6;
      position: absolute;
      right: 0;
      top: 50%;
      opacity: 0.2;
      transform: translateY(-50%);
      list-style: none;
    }
  }