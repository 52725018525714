.section_mission {
    max-width: 100%;
    /* height: 25rem; */
    margin: 0 auto;
    text-align: center;
    padding: 30px;
    background-color: #ececec;
    margin-bottom: 50px;
  }
  .section-lead {
    max-width: 600px;
    margin: 1rem auto 1.5rem;
  }
  
  .service a {
    color: #5b7abb;
    display: block;
  }
  
  .service h4 {
    font-weight: 600;
    color: #56ceba;
    font-size: 36px;
    margin: 0.2rem 0 0.2rem;
  }
  
  .services-grid {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
  }
  
  .service {
    background: #fff;
    margin: 20px;
    padding: 20px;
    border-radius: 4px;
    text-align: center;
    -webkit-box-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    border: none;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  
  .service:hover {
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.08);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.08);
  }
  
  .service i {
    font-size: 4rem;
    margin: 0.2rem 0;
  }

  .points ul li{
    text-align: justify;
    margin: 10px;
  }
  
  .service1 i,
  .service1 h4,
  .service1 .cta {
    color: #ff4500;
  }
  
  .service1:hover {
    border: 1px solid #ff4500;
  }
  
  .service2 i,
  .service2 h4,
  .service2 .cta {
    color: #ff4500;
  }
  
  .service2:hover {
    border: 1px solid #ff4500;
  }
  
  .service3 i,
  .service3 h4,
  .service3 .cta {
    color: #ff4500;
  }
  
  .service3:hover {
    border: 1px solid #ff4500;
  }
  
  .service .cta span {
    font-size: 0.6rem;
  }
  
  .service > * {
    flex: 1 1 100%;
  }
  
  .service .cta {
    align-self: flex-end;
  }
  
  @media all and (max-width:900px) {
    .services-grid {
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
    }
  }