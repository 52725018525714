.footer {
  width: 100%;
  background: #14273E;
  display: block;
  bottom: 0;
}

.inner-footer {
  width: 95%;
  margin: auto;
  padding: 30px 10px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: center;
}

.footer-items {
  width: 25%;
  padding: 10px 20px;
  box-sizing: border-box;
  color: #fff;
}

.footer-items p {
  font-size: 16px;
  text-align: justify;
  line-height: 25px;
  color: #fff;
}

.footer-items h1 {
  color: #fff;
}

.border1 {
  height: 3px;
  width: 40px;
  background: #fff;
  color: #fff;
  background-color: #fff;
  border: 0px;
}

.ul {
  color: #fff;
  font-size: 15px;
  letter-spacing: 0.5px;
  padding-left: 0; /* Remove the default left padding */
}

.ul a {
  text-decoration: none;
  outline: none;
  color: #fff;
  transition: 0.3s;
  display: block;
  margin: 10px 0;
  height: 25px;
}

.ul a:hover {
  color: #fff;
}

.li {
  margin: 10px 0;
  /* margin-top: 20px; */
  /* padding-bottom: 20px; */
  height: 15px;
}

.DKM{
  margin-bottom: 50px;
}

.li i {
  margin-right: 20px;
}

.social-media {
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 20px;
}

.social-media a {
  text-decoration: none;
}

.social-media i {
  height: 25px;
  width: 25px;
  margin: 20px 10px;
  padding: 4px;
  color: #fff;
  transition: 0.5s;
}

.social-media i:hover {
  transform: scale(1.5);
}

.footer-bottom {
  padding: 10px;
  border-top: 1px solid #fff;
  background: #14273E;
  color: #fff;
  font-size: 12px;
  text-align: center;
}

/* Make all text color white */
.footer * {
  color: #fff;
}

.footer-items h3 {
  color: #fff;
}

/* Remove dots from list items */
.ul li {
  list-style: none;
}
#li_phone{
  margin-top: 20px;
}
/* Responsive Styling */

@media screen and (max-width: 1024px) {
  .inner-footer {
    flex-direction: column;
  }

  .footer-items {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .footer-items {
    width: 100%;
  }

  .inner-footer {
    padding: 20px 10px;
  }
}
