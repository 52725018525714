:root {
    --clr-text: #1e2246;
  }
  
  .wrap {
    width: min(100%, 100rem);
  }

  .head_career_form{
    display: flex;
    justify-content: center;
    margin: auto;
    padding-top: 40px;
    font-size: 20px;
  }

  .head_career{
    display: flex;
    justify-content: center;
    margin: auto;
    font-size: 38px;
    margin-top: 30px;
    font-weight: 800;
  }
  
  .positions {
    margin-block: 2rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3rem;
  }
  
  .position {
    flex: 0 0 calc(30.33% - 2rem); /* Adjust this value as needed */
    padding: 3rem;
    background: #ffffff;
    box-shadow: 10px 20px 40px rgba(180, 191, 225, 0.4);
    border: 1px solid #f0eeee;
    border-radius: 16px;
  }
  
  .position header {
    display: flex;
    gap: 1.5rem;
    margin-block-end: 1.5rem;
  }
  
  .position__name {
    font-size: 1.6rem;
    font-weight: 500;
  }

  .psn{
    font-weight: 600;
  }
  
  .positions.center {
    justify-content: flex-start;
  }
  
  .btn {
    font: inherit;
    font-size: 1.5rem;
    /* font-weight: 600; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 40%;
    padding: 0.8rem;
    color: inherit;
    text-align: center;
    background: #ff4500;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition-property: color, background-color, border-color;
    transition-duration: 0.25s;
    text-decoration: none;
  }
  
  .btn:hover {
    color: #fff;
    border-color: var(--clr-text);
    background: var(--clr-text);
  }

  .no-positions-animation {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    margin: 15rem 0;
  }
  
  .no-positions-text {
    font-size: 2rem;
    animation: slide-in 1s ease-in-out infinite alternate;
  }
  
  @keyframes slide-in {
    0% {
      opacity: 0;
      transform: translateY(-50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  
  footer {
    opacity: 0.7;
    text-align: center;
    max-width: 70ch;
    margin-inline: auto;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  