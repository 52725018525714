/*RECOMMENDED ARTICLES*/
.clear {
  clear: both;
}
.product__resources {
  margin: 0px 0 20px;
  background: #fafafa;
  width: 100vw;
  padding: 50px 0 50px;
}

.top_headd h1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.product__resources .product__cont {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 30px 0;
}

.product__cont h2 {
  text-align: center;
  margin: 0 0 60px;
  color: #003a79;
}
.product__resources__item {
  width: calc(33.33% - 30px);
  box-sizing: border-box;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  margin: 15px;
  padding: 15px;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  background: #fff;
}

.product__resources__item__img {
  height: 200px; 
  width: 100%;
  position: relative;
  background-size: contain !important;
  background-position: center !important;

}


.product__resources__item h3 {
  padding: 0 30px;
  color: #003a79;
}
.product__resources__item p {
  padding: 0 30px;
  line-height: 1.45;
  font-style: none;
}

.fa-arrow-right{
  color: #fff;
  font-size: 18px;
  margin-left: 10px;
  transition: all 0.3s linear;
}
a.article-Button {
  padding: 15px 78px 15px 30px;
  margin: 15px 30px -25px 0px;
  border-radius: 30px;
  text-align: center;
  vertical-align: middle;
  font-weight: 600;
  /* text-transform: uppercase; */
  font-size: 20px;
  color: #ffffff;
  transition: all 0.3s linear;
  text-decoration: none;
  width: auto;
  background-color: orangered;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  float: right;
}

.product__resources__item:hover {
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
  -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
}
.product__resources__item a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.article__bottom {
  padding: 15px 78px 15px 30px;
  margin: 15px 0 -25px 0;
  position: absolute;
  bottom: 10px;
}
.article__bottom p {
  margin: 0;
  font-style: none;
}
@media screen and (max-width: 695px) {
  body {
    height: 100%;
  }
  .product__resources__item {
    width: 100%;
    margin: 30px 0 60px;
    display: block;
  }
}

/*END : RECOMMENDED ARTICLES*/

.head {
  font-size: 26px;
  color: #003a79;
}

.head2 {
  font-size: 18px;
  color: #003a79;
}
.description {
  font-size: 22px;
  color: #003a79;
}
